import requests from "@/utils/axios";
import baseApi from "@/api/baseApi";

export default {
    ...baseApi,
    // 模块URL
    modulesUrl: "/attendance/duty/balanceDown",
    levelSelect(query: any) {
        return requests.post(this.modulesUrl + "/levelSelect", query);
    },
    add(query: any) {
        return requests.post(this.modulesUrl + "/add", query);
    },
    dataBasicUpload(query: any) {
        return requests.post(this.modulesUrl + "/dataBasicUpload", query);
    },
};
